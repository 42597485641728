<template>
  <div>
    <main-hero
      title="Kilpailuta Remonttilaina maksutta"
      subtitle="Yhdellä hakemuksella useita lainatarjouksia."
      side-title="Hae remonttilainaa ja vertaa!"
      :side-boxes="sideBoxes"
      survey-title="Asiakkaidemme arviot"
      :breadcrumb="{'Lainaa': '/lainaa', 'Remonttilaina': ''}"
    />
    <section class="hero has-background-rlgray">
      <div class="hero-body">
        <div class="container">
          <h2 class="title has-text-white is-size-4 has-text-centered">
            Remonttilaina kannattaa kilpailuttaa.
          </h2>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <loan-steps />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <h3>
            Kauttamme voit hakea remonttilainaa jopa 70 000 € vakuudetta.
          </h3>
          <p>
            Kodin tai vapaa-ajan paikan remontoiminen tulee meille jokaiselle eteen jossain kohtaa. Käytät sitten ammattilaista tai teet itse, joutuu siihen useasti ottamaan lainaa. Tässä kohtaa kannattaa kilpailuttaa lainantarjoajat.
          </p>
          <p>
            Täysin kotimainen Rahalaitos kilpailuttaa lainan eduksesi jopa yli 25 luotonmyöntäjän kesken. Parasta on, että saat avullamme vertailtavaksi kerralla suuren määrän lainanmyöntäjiä. Palvelu on sinulle täysin ilmainen, etkä hakemalla vielä sitoudu mihinkään.
          </p>
          <h2>
            5 tärkeää syytä kilpailuttaa remonttilaina kauttamme
          </h2>
          <p>
            Rahalaitos on kilpailuttanut jo satojatuhansia suomalaisten lainahakemuksia asiakkaidensa eduksi. Palvelussamme kilpailutat parissa minuutissa 500 – 70 000 euron suuruiset remonttilainat ilman vakuuksia. Lainaa voit ottaa ja käyttää oman tarpeesi mukaan.
          </p>
          <h3>
            1. Kilpailuttaminen on maksutonta etkä sitoudu mihinkään
          </h3>
          <p>
            Ensimmäiset lainatarjoukset saat vertailtavaksi usein heti hakemuksen jättämisen jälkeen. Henkilökohtaisilla Oma Rahalaitos sivuilla voit verrata omassa tahdissa kilpailutuksen edistymistä. Näet selkeästi mm. eri tarjouksien lainasummat, kuukausierät sekä korot ja kulut. Jos tarjoukset eivät tunnu sopivilta niin voit olla valitsematta eikä sinulle tästä koidu mitään kuluja.
          </p>
          <h3>
            2. Remonttilainan löydät verkosta nopeimmin
          </h3>
          <p>
            Perinteisesti pankkien kanssa asioiminen on hidasta. Jos olet halunnut vertailla eri pankkeja, on prosessista tullut liian raskas ja aikaa vievä. Palvelussamme täytät vain yhden hakemuksen, jonka avulla löydät sopivan lainan tarpeeseesi.
          </p>
          <p>
            Käsittely hakemuksesta tilille maksuun on ripeää. Nopeimmillaan remonttilaina maksetaan tilillesi vielä samana päivänä.
          </p>
          <h3>
            3. Remonttilainaan ei tarvitse vakuuksia tai takaajia
          </h3>
          <p>
            Halvin remonttilaina löytyy aina vertailemalla. Teemme tässä kohtaa vertailemisen helpoksi. Koska saat kauttamme useita parhaita mahdollisia lainatarjouksia näet selkeästi mikä sinulle näistä olisi sopivin maksuehdoiltaan ja hinnaltaan.
          </p>
          <p>
            Kauttamme haettuihin lainoihin et tarvitse vakuuksia tai takaajia. Tämä nopeuttaa ja helpottaa lainaprosessia, silti saat kilpailukyisen rahoituksen remontillesi.
          </p>
          <h3>
            4. Lainan käyttötarkoitusta ei ole rajattu
          </h3>
          <p>
            Lainaa hakiessasi et joudu erittelemään mihin lainaa olet käyttämässä. Oli kyse sitten keittiö-, vapaa-ajan asunnon- tai muuhun kodin ehostukseen liittyvästä remontista, voit käyttää lainan sinulle tarpeellisimpaan asiaan – vaikka sisustuksen uudistamiseen.
          </p>
          <h3>
            5. Joustava ja sinulle sopiva maksuaikataulu
          </h3>
          <p>
            Remonttilainan takaisinmaksuajaksi voit valita 1-15 vuotta. Mikäli kuitenkin haluat maksaa lainan aikaisemmin takaisin, niin se onnistuu. Tällöin säästät jäljellä olevan poismaksetun lainan osalta rahaa lainakustannuksissa.
          </p>
          <h3>
            Remonttilainaa voit hakea, kun nämä ehdot täyttyvät
          </h3>
          <ul>
            <li>Vähintään 20 vuoden ikä.</li>
            <li>Kuukausitulot yli 600 euroa.</li>
            <li>Ei julkisia maksuhäiriömerkintöjä.</li>
          </ul>
          <h3>
            Vertaa Remonttilainoja nyt!
          </h3>
          <p>
            Miksi et kokeilisi sinulle sopivimman remonttilainan etsimistä jo tänään? Täytä lainahakemus ja löydät edullisen remonttilainan verkosta jo tänään. Saat hetkessä lainapäätöksen ja pääset aloittamaan remontti toteuttamisen.
          </p>
          <p>
            Kauttamme lainan hakeminen on maksutonta, turvallista ja riskitöntä. Edullisin remonttilaina on aina kilpailutuksen tulos, joten miksi enää odottaisit?
          </p>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <subpages />
        </lazy-hydrate>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <lazy-hydrate when-visible>
          <banks />
        </lazy-hydrate>
      </div>
    </section>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'AutoLaina',
  components: {
    LazyHydrate,
    banks: () => import( '~/components/layout/banks' ),
    loanSteps: () => import( '~/components/layout/loanSteps' ),
    mainHero: () => import( '~/components/layout/mainHero' ),
    subpages: () => import( '~/components/layout/subpages' )
  },
  async fetch ( { store } ) {
    await store.dispatch( 'subpage/setPages', [
      {
        title: 'Lainaa ilman takaajia',
        subtitle: 'Haaveista totta nopeasti ja helposti',
        link: '/lainaa-ilman-takaajia'
      },
      {
        title: 'Lainaa 30 000 euroa',
        subtitle: 'Suuriin ja pieniin unelmiin',
        link: '/lainaa-30000-euroa'
      },
      {
        title: 'Yhdistä lainasi',
        subtitle: 'Selkiytä talouttasi ja säästä kuluissa',
        link: '/yhdista-lainat'
      }
    ] )
  },
  data () {
    return {
      sideBoxes: [
        {
          icon: () => import( '~/assets/img/icons/banks.svg?inline' ),
          title: 'Tavoita jopa',
          subtitle: '25 pankkia'
        },
        {
          icon: () => import( '~/assets/img/icons/time.svg?inline' ),
          title: 'Saat nopeasti',
          subtitle: 'tarjouksia'
        },
        {
          icon: () => import( '~/assets/img/icons/money.svg?inline' ),
          title: 'Remonttiin lainaa jopa',
          subtitle: '70 000 €'
        }
      ]
    }
  },
  head () {
    return {
      title: 'Remonttilaina - Edullinen remonttilaina | Rahalaitos',
      meta: [
        { hid: 'description', name: 'description', content: 'Kodin tai vapaa-ajan paikan remontoiminen tulee meille jokaiselle eteen jossain kohtaa. Rahalaitoksen kautta voit hakea remonttilainaa jopa 70 000 €.' }
      ],
      link: [
        { rel: 'canonical', href: 'https://www.rahalaitos.fi/remonttilaina' }
      ]
    }
  }
}
</script>
