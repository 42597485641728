<template>
  <form-select
    ref="toimiala"
    key="form.toimiala"
    v-validate="'required'"
    :error="errors.first('toimiala')"
    :value="value"
    name="toimiala"
    label="Toimiala"
    placeholder="Toimiala"
    @input="$emit('input', $event)"
  >
    <option value="1">Rahoitus- ja vakuutustoiminta</option>
    <option value="2">Rakentaminen</option>
    <option value="3">Kaivos- ja kaivannaistoiminta</option>
    <option value="4">Palvelu, muut</option>
    <option value="5">Energia- ja vesihuolto</option>
    <option value="6">Majoitustoiminta</option>
    <option value="7">Kauppa</option>
    <option value="8">Koulutus</option>
    <option value="9">Sosiaalipalvelut</option>
    <option value="10">Ravitsemustoiminta</option>
    <option value="11">Tavarakuljetus</option>
    <option value="12">Henkilökuljetus</option>
    <option value="13">Kiinteistö- ja vuokrauspalvelut</option>
    <option value="14">Tekninen palvelu ja palvelut liike-elämälle</option>
    <option value="15">Teollisuus</option>
    <option value="16">Julkinen hallinto</option>
    <option value="17">Järjestötoiminta</option>
    <option value="18">Eläkeläinen</option>
    <option value="19">Maanpuolustus</option>
    <option value="20">Maatalous, kala- ja riistatalous</option>
    <option value="21">Metsätalous</option>
    <option value="22">Siivous- ja pesulatoiminta</option>
    <option value="23">Suojelu, poliisi, palolaitos</option>
    <option value="24">Terveyspalvelut</option>
    <option value="25">Tietoliikenne</option>
    <option value="26">Tutkimus</option>
    <option value="27">Uskonnollinen toiminta</option>
    <option value="28">Virkistys- ja kulttuuripalvelut</option>
    <option value="29">Opiskelija/työtön</option>
    <option value="30">Tuntematon/en osaa sanoa</option>
  </form-select>
</template>

<script>
export default {
  inject: {
    $validator: '$validator'
  },
  components: {
    formSelect: () => import('~/components/form/select')
  },
  props: {
    value: {
      default: null,
      type: [String, Number]
    }
  }
}
</script>
