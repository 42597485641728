<template>
  <div>
    <business-application ref="appCont" />
  </div>
</template>

<script>

import * as Resize from 'iframe-resizer'
import Vue from 'vue'

Vue.use( Resize )

export default Vue.extend( {
  components: {
    businessApplication: () => import( '~/components/businessApplication' )
  },
  layout: 'iframe',
  head () {
    return {
      meta: [
        { hid: 'robots', name: 'robots', content: 'noindex' }
      ]
    }
  },
  mounted () {
    window.iFrameResizer = {
      onMessage: ( message ) => {
        // Fill applications if message contains application info
        if (Object.prototype.hasOwnProperty.call( message, 'application' ) ) {
          const appData = message.application
          // Application loaded
          if ( this.$refs.appCont.$refs.application ) {
            this.$refs.appCont.$refs.application.mergeFormData( appData )
          }
          // Application not loaded
          else {
            this.$store.dispatch( 'loanApplication/merge', appData )
          }
        }
      }
    }
  }
} )
</script>
