<template>
  <section class="hero main-hero">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half">
            <div class="content" style="background-color: white; padding: 3rem; border-radius: 10px;">
              <h1>Tervehdys,</h1>
              <p>
                Olet aikeissa poistua postituslistaltamme. Markkinointiviesteissä kerromme viimeisimmän tiedon
                markkinoiden parhaimmista lainatarjouksista ja eduistamme.
              </p>
              <p>Ystävällisin terveisin,<br>Rahalaitos.fi</p>
              <div class="box has-background-white-ter">
                <form
                  v-if="!successPhone"
                  method="post"
                  data-vv-scope="sms"
                  @submit.prevent="validate('sms')"
                >
                  <form-input
                    key="form.phone"
                    v-model="form.phone"
                    v-validate="'required|phone_stripped'"
                    :error="errors.first('sms.phone')"
                    name="phone"
                    label="Puhelinnumero"
                    placeholder="esim. 040 123 4567"
                  />
                  <div class="field">
                    <p class="control">
                      <button
                        :disabled="submitLoading"
                        class="button is-primary is-rounded is-fullwidth"
                        type="button" @click="validate('sms')"
                      >
                        <span>Poista puhelinnumero</span>
                        <span v-if="submitLoading" class="icon">
                          <font-awesome-icon :icon="['fas', 'spinner-third']" spin />
                        </span>
                      </button>
                    </p>
                  </div>
                </form>
                <p v-else class="is-size-5 has-text-weight-bold has-text-rlgreen">
                  Antamasi numero on poistettu postituslistalta.
                </p>
              </div>
              <div class="box has-background-white-ter">
                <form
                  v-if="!successEmail"
                  method="post"
                  data-vv-scope="email"
                  @submit.prevent="validate('email')"
                >
                  <form-input
                    key="form.email"
                    v-model="form.email"
                    v-validate="'required|email'"
                    :error="errors.first('email.email')"
                    name="email"
                    label="Sähköposti"
                    placeholder="esim. matti.meikalainen@esimerkki.fi"
                  />
                  <div class="field">
                    <p class="control">
                      <button
                        :disabled="submitLoading"
                        class="button is-primary is-rounded is-fullwidth"
                        type="button" @click="validate('email')"
                      >
                        <span>Poista sähköposti</span>
                        <span v-if="submitLoading" class="icon">
                          <font-awesome-icon :icon="['fas', 'spinner-third']" spin />
                        </span>
                      </button>
                    </p>
                  </div>
                </form>
                <p v-else class="is-size-5 has-text-weight-bold has-text-rlgreen">
                  Antamasi sähköposti on poistettu postituslistalta.
                </p>
              </div>
              <p class="control">
                <button
                  class="button is-rounded is-fullwidth"
                  type="button" @click="$router.push({ path: '/' })"
                >
                  Palaa etusivulle
                </button>
              </p>
              <div v-if="showError" class="notification is-danger">
                <p>Tapahtui odottamaton järjestelmävirhe.</p>
                <p>
                  Pahoittelemme tapahtunutta. Yritä myöhemmin uudelleen. Mikäli ongelma toistuu edelleen, ota yhteyttä
                  asiakaspalveluumme.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue'

export default Vue.extend( {
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    formInput: () => import( '~/components/form/input.vue' )
  },
  data () {
    return {
      successPhone: false,
      successEmail: false,
      showError: false,
      submitLoading: false,
      form: {
        phone: '',
        email: ''
      }
    }
  },
  head () {
    return {
      meta: [
        { hid: 'robots', name: 'robots', content: 'noindex' }
      ]
    }
  },
  methods: {
    async validate ( scope ) {
      this.showError = false
      this.submitLoading = true
      await this.$validator.validate( `${scope}.*` )
      // Using errorbag as result because validate function has bug, will always return true.
      const hasErrors = this.$validator.errors.items.length
      if ( hasErrors ) {
        const fieldEl = this.$el.querySelector( `[name="${this.$validator.errors.items[0].field}"]` )
        if ( fieldEl ) {
          fieldEl.scrollIntoView( { block: 'center' } )
        }
        else {
          this.$logger.error( 'Can\'t find field', {
            metaData: {
              'Validator errors': this.$validator.errors
            }
          } )
        }
        this.submitLoading = false
      }
      else {
        await this.sendRequest()
        this.submitLoading = false
      }
    },
    async sendRequest () {
      let result = {}
      try {
        result = await this.$axios.$post(
          '/api/Customer/Marketing',
          Object.fromEntries( Object.entries( this.form ).filter( ( [_, v] ) => v !== '' ) ) // Remove empty keys
        )
      }
      catch ( error ) {
        this.showError = true
        this.$logger.error( error )
      }
      if ( Object.prototype.hasOwnProperty.call( result, 'phone' ) && result.phone !== false ) {
        if ( result.phone === true ) {
          this.successPhone = true
          this.$ga.event( { eventCategory: 'Markkinointikielto', eventAction: 'SMS' } )
          this.$gtag.event( 'ban', { event_category: 'marketing', event_label: 'SMS' } )
        }
        else if (
          typeof result.phone === 'object' &&
          Object.prototype.hasOwnProperty.call( result.phone, 'message' )
        ) {
          const field = this.$validator.fields.find( { name: 'phone' } )
          field.setFlags( { invalid: true } )
          field.validator.errors.add(
            { field: field.name, msg: result.phone.message, id: field.id, scope: field.scope }
          )
        }
      }
      if ( Object.prototype.hasOwnProperty.call( result, 'email' ) && result.email !== false ) {
        if ( result.email === true ) {
          this.successEmail = true
          this.$ga.event( { eventCategory: 'Markkinointikielto', eventAction: 'Email' } )
          this.$gtag.event( 'ban', { event_category: 'marketing', event_label: 'Email' } )
        }
        else if (
          typeof result.email === 'object' &&
          Object.prototype.hasOwnProperty.call( result.email, 'message' )
        ) {
          const field = this.$validator.fields.find( { name: 'email' } )
          field.setFlags( { invalid: true } )
          field.validator.errors.add(
            { field: field.name, msg: result.email.message, id: field.id, scope: field.scope }
          )
        }
      }
    }
  }
} )
</script>
